#privacy_policy {
    ul.lst-kix_kw3s4jc12hcg-7 {
        list-style-type: none
    }

    ul.lst-kix_kw3s4jc12hcg-8 {
        list-style-type: none
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_kw3s4jc12hcg-5 > li:before {
        content: "\0025a0   "
    }

    ul.lst-kix_kw3s4jc12hcg-3 {
        list-style-type: none
    }

    ul.lst-kix_kw3s4jc12hcg-4 {
        list-style-type: none
    }

    ul.lst-kix_kw3s4jc12hcg-5 {
        list-style-type: none
    }

    .lst-kix_kw3s4jc12hcg-4 > li:before {
        content: "\0025cb   "
    }

    .lst-kix_kw3s4jc12hcg-6 > li:before {
        content: "\0025cf   "
    }

    ul.lst-kix_kw3s4jc12hcg-6 {
        list-style-type: none
    }

    ul.lst-kix_kw3s4jc12hcg-0 {
        list-style-type: none
    }

    ul.lst-kix_kw3s4jc12hcg-1 {
        list-style-type: none
    }

    ul.lst-kix_kw3s4jc12hcg-2 {
        list-style-type: none
    }

    .lst-kix_kw3s4jc12hcg-3 > li:before {
        content: "\0025cf   "
    }

    .lst-kix_kw3s4jc12hcg-7 > li:before {
        content: "\0025cb   "
    }

    .lst-kix_kw3s4jc12hcg-0 > li:before {
        content: "\0025cf   "
    }

    .lst-kix_kw3s4jc12hcg-2 > li:before {
        content: "\0025a0   "
    }

    .lst-kix_kw3s4jc12hcg-8 > li:before {
        content: "\0025a0   "
    }

    .lst-kix_kw3s4jc12hcg-1 > li:before {
        content: "\0025cb   "
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c2 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

    .c7 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

    .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

    .c0 {
        padding-top: 12pt;
        padding-bottom: 12pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c9 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: italic
    }

    .c5 {
        border-left-style: solid;
        padding-left: 30pt;
        border-left-width: 0pt
    }

    .c4 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline
    }

    .c8 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt
    }

    .c10 {
        color: inherit;
        text-decoration: inherit
    }

    .c11 {
        padding: 0;
        margin: 0
    }

    .c6 {
        margin-left: 36pt;
        padding-left: 0pt
    }

    .c12 {
        font-style: italic
    }

    .c3 {
        font-weight: 700
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
}
