.listItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
}
.btn_delete{
    width: 40px;
    height: 40px;
    min-width: auto !important;
    border-radius: 50% !important;
    margin: 0 5px !important;
}

