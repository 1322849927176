.maplibregl-user-location-dot:before {
    left: 0 !important;
}

.maplibregl-ctrl-bottom-left {
    bottom: 46px !important;
    user-select: none;
}

.maplibregl-ctrl-bottom-right {
    bottom: 46px !important;
}

.maplibregl-ctrl-icon {
    background-size: 100% 100%;
}

.maplibregl-ctrl-group button {
    width: 44px;
    height: 44px;
}
