:root {
  --textDarck: #052B33;
}


.App {
  text-align: center;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

p, h2, h4 {
  color: var(--textDarck);
}

html, body {
  overscroll-behavior-y: none;
}

.refresh-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1200;
  background-color: #ffffff;
}

.refresh-content {
  height: 100%;
  width: 100%;
}
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin: 15px auto;
}

@keyframes l5 {
  0%  {box-shadow: 20px 0 #06572b, -20px 0 #1dec7b;background: #06572b }
  33% {box-shadow: 20px 0 #06572b, -20px 0 #148748;background: #148748}
  66% {box-shadow: 20px 0 #148748,-20px 0 #06572b; background: #148748}
  100%{box-shadow: 20px 0 #148748,-20px 0 #06572b; background: #06572b }
}
.refresh-content_show{
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.refresh-content_hide{
  opacity: 0;
  display: none;
}


.navbar {
  padding-bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
}

.productImg{
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.productImgEmpty{
  background: #f2f2f2;
  border:none;
}
.productImg img{
  max-height: 100%;
  object-fit: cover;
}
.productItem{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.productImgIcon{
  font-size: 80px !important;
  opacity: 0.2;
}
