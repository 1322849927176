video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.readerContainer {
    position: relative;
    background-color: black;
    width: 100%;
    height: 100%;
}
.helpContainer {
    position: absolute;
    background-color: white;
    z-index: 55;
    width: 100%;
    height: 100%;
    color: #0A9E4D;
}

.loadingOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5rem;
}

.resultText {
    position: absolute;
    color: white;
    left: 1rem;
}

.cameraMenuWrapper {
    position: absolute;
    right: 0;
}
