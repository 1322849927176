.item {
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 6px;
    margin: 8px 16px;
}

.dashed-border:before {
    content: "";
    position: absolute;
    border-left: 3px dashed #D0DBE7;
    top: -1.6px;
    bottom: -1.6px;
    left: -1.6px;
    right: -1.6px;
}

.dashed-border {
    overflow: hidden;
    position: relative;
}



