.fullElement {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notFullElement {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}